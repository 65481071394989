#navbar {
  font-family: "Public Sans", sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  #logo {
    width: 100px;
    height: auto;
  }
  .linksContainer {
    width: 100%;
  }
  .nav-link {
    color: #a3a1a1;
    font-size: 20px;
    margin-right: 10px;
    &:hover {
      color: #c9c8c8;
    }
  }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: #ffffff;
  }
  #navbarSocials {
    display: flex;
    gap: 8px;
    margin-right: 18px;
    .socialLink {
      font-size: 24px;
      color: #a3a1a1;
      &:hover {
        color: #c9c8c8;
      }
    }
  }
}

@media (min-width: 992px) {
  #navbar {
    .linksContainer {
      align-items: center;
    }
  }
}

@media (max-width: 991px) {
  #navbarSocials {
    margin-right: 0px;
    margin-bottom: 12px;
  }
}
