#landingPage {
  background-image: url("../../assets/img/landing-page-background.JPG");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  #logoFulcrum {
    width: 200px;
    margin-right: 50px;
  }
  #logoArmadillo {
    width: 150px;
  }
  #contentContainer {
    background-color: #ffffffa9;
    margin-top: 10px;
    margin-bottom: 120px;
    padding: 20px;
    text-align: center;
    #landingPoster {
      width: 90%;
      max-width: 600px;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    #locationButton {
      margin-left: 20px;
    }
    .subtitle {
      margin-top: 70px;
    }
    #formContainer,
    #mapContainer {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    #formContainer {
      text-align: left;
    }
    #spinner {
      margin-top: 50px;
    }
  }
  @media (max-width: 550px) {
    #logoFulcrum {
      width: 150px;
      margin-right: 20px;
    }
    #logoArmadillo {
      width: 100px;
    }
  }
}
