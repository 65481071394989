#galeria {
  margin-top: 80px;
  #containerGaleria {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  .galleryImage {
    width: 30%;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    &:hover {
      width: 33%;
      box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.651);
    }
  }
}
#navigationButtonsContainer {
  display: flex;
  gap: 20px;
  .navigationButton {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
#modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#galleryModalImage {
  width: 100%;
  height: auto;
  margin: 10px;
}
#galleryModalFooter {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  #galeria {
    #containerGaleria {
      gap: 10px;
    }
  }
}
